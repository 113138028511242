var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('div',[_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":function($event){return _vm.closeSidebar()}}})],1)]),_c('b-card-code',{attrs:{"title":"Cloud Discovery"}},[(_vm.loading)?_c('div',{staticClass:"d-flex justify-content-center mb-1"},[_c('b-spinner',{staticClass:"float-right",attrs:{"label":"Floated Right"}})],1):_c('div',[(_vm.items.length)?_c('b-table',{attrs:{"responsive":"","id":"atTable","fields":_vm.fields,"items":_vm.items,"per-page":_vm.perPage,"hover":""},scopedSlots:_vm._u([{key:"cell(index)",fn:function(data){return [_vm._v(" "+_vm._s(data.index + 1 + _vm.perPage * (_vm.currentPage - 1))+" ")]}},{key:"cell(actions)",fn:function(data){return [(data.item.is_newly_founded && data.item.ip_address != null)?_c('div',[(
                data.item.is_newly_founded && data.item.ip_address != null
              )?_c('b-button',{staticStyle:{"position":"relative"},attrs:{"variant":"outline-warning","disabled":_vm.isDisableIP,"size":"sm"},on:{"click":function($event){return _vm.addToAssets(data.item.id, 'ip')}}},[(_vm.isLoadingIP && data.index === _vm.currentIndexIP)?_c('span',[_c('b-spinner',{attrs:{"type":"border","small":""}}),_vm._v("Please wait")],1):_c('span',[_vm._v("Add to assets")])]):_vm._e()],1):(
              !data.item.added_to_asset && data.item.ip_address != null
            )?_c('div',[_c('b-button',{staticStyle:{"position":"relative"},attrs:{"variant":"outline-primary","disabled":_vm.isDisableIP,"size":"sm"},on:{"click":function($event){return _vm.addToAssets(data.item.id, 'ip')}}},[(_vm.isLoadingIP && data.index === _vm.currentIndexIP)?_c('span',[_c('b-spinner',{attrs:{"type":"border","small":""}}),_vm._v("Please wait")],1):_c('span',[_vm._v("Add to assets")])])],1):(
              data.item.added_to_asset && data.item.ip_address != null
            )?_c('div',[_c('b-button',{staticStyle:{"position":"relative","padding-left":"18px","padding-right":"18px"},attrs:{"variant":"outline-success","disabled":!data.item.asset_ref_id,"size":"sm"},on:{"click":function($event){return _vm.$router.push({
                  path: '/assets/scan/' + data.item.asset_ref_id,
                })}}},[_vm._v(" Go to Assets ")])],1):_vm._e()]}}],null,false,221960674)}):_c('div',[_c('div',{staticClass:"w-100 d-flex flex-column align-items-center justify-content-center py-4"},[_c('feather-icon',{staticClass:"mb-50",attrs:{"icon":"DatabaseIcon","size":"34"}}),_c('h3',{staticClass:"font-weight-bolder"},[_vm._v("No Data Found")])],1)])],1),_c('b-pagination-nav',{attrs:{"numberOfPages":_vm.total,"total-rows":_vm.rows,"link-gen":_vm.linkGen,"per-page":_vm.perPage,"align":_vm.pagination_pos,"aria-controls":"atTable","use-router":""},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }