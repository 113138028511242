<template>
  <div>
    <b-card class="bg-dark-blue" style="padding-top: 15px">
      <div class="row">
        <div class="col-12">
          <validation-observer ref="observer" #default="{ invalid }">
            <b-form @submit.prevent>
              <div class="row">
                <div class="col-10">
                  <b-form-group class="">
                    <validation-provider
                      #default="{ errors }"
                      name="Configuration"
                      rules="required"
                    >
                      <b-input-group style="flex-wrap: nowrap !important">
                        <v-select
                          v-model="configuration_filter"
                          label="text"
                          @search="fetchConfiguration"
                          class="ml-1"
                          placeholder="--Select Configuration--"
                          :options="configuration_filter_options"
                          autocomplete
                          style="width: 100%"
                          :reduce="
                            (configuration_filter) => configuration_filter.value
                          "
                        />
                        <b-input-group-append>
                          <b-button
                            variant="primary"
                            @click="gotoCreateCloud()"
                            v-b-tooltip.hover.v-dark
                            title="Add New Cloud Configuration"
                          >
                            +
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-2">
                  <div class="d-flex justify-content-end w-100">
                    <button
                      @click="scanCreate()"
                      type="button"
                      class="btn btn-primary w-100"
                      :disabled="invalid"
                      style="padding-top: 13px; padding-bottom: 13px"
                    >
                      <span v-if="isloading"
                        ><b-spinner type="border" small></b-spinner> Please
                        wait</span
                      >
                      <span v-else
                        ><feather-icon
                          icon="SearchIcon"
                          class="mr-50"
                        />Scan</span
                      >
                    </button>
                  </div>
                </div>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </b-card>
    <b-tabs v-model="cloudTabIndex">
      <b-tab title="Scans" Active>
        <CloudScanList ref="cloudScan" :tabIndex="cloudTabIndex" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BCard,
  BPagination,
  BPaginationNav,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BForm,
  BFormInput,
  BFormSelect,
  BSpinner,
  BButton,
  BBadge,
  BSidebar,
  BFormGroup,
  VBToggle,
  BTabs,
  BTab,
  BFormSelectOption,
  BInputGroup,
  BInputGroupAppend,
  VBTooltip,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import CloudScanList from "./cloudConfigScan.vue";

import flatPickr from "vue-flatpickr-component";
import moment from "moment-timezone";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BForm,
    VBToggle,
    BFormInput,
    BFormSelect,
    BSpinner,
    BButton,
    BBadge,
    // AssetsDiscover,
    BSidebar,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BTabs,
    BTab,
    BFormSelectOption,
    flatPickr,
    vSelect,
    CloudScanList,
    BInputGroup,
    BInputGroupAppend,
  },
  props: {
    agent_types: {
      type: Array,
      required: true,
    },
    tabIndex: {
      type: Number,
      required: true,
    },
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      loading: false,
      isloading: false,
      cloudTabIndex: 0,
      configuration_filter: null,
      configuration_filter_options: [],
    };
  },
  computed: {},
  mounted: function () {
    // if (this.DomainTabIndex === 0) {
    //   this.$refs.domainScan.load();
    // }
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY hh:mm A");
      }
    },
  },
  methods: {
    linkGen: function (pageNum) {
      return this.$router.currentRoute.path + "?page=" + pageNum;
    },
    fetchConfiguration: function (search) {
      if (search.length > 0) {
        const a_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_BASEURL + "organization/cloud-config",
        };
        var self = this;
        this.$http(a_options).then((res) => {
          self.configuration_filter_options = [];
          res.data.results.map(function (value, key) {
            let a = {
              value: res.data.results[key].id,
              text: res.data.results[key].nice_name,
            };
            self.configuration_filter_options.push(a);
          });
        });
      }
    },
    assets_discovered: function (id) {
      this.scan_id = id;
      this.$root.$emit("bv::toggle::collapse", "sidebar-task-handler");
    },
    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-task-handler");
    },
    scanCreate() {
      this.isloading = true;
      const selectedAgentType = this.agent_types.find(
        (item) => item.agent_type_code === "cloud_discovery"
      );
      this.agent_id = selectedAgentType
        ? selectedAgentType.agent_type_id
        : null;
      let data = {
        agent_type: this.agent_id,
        scan_created_by: this.$store.state.app.user.id,
        cloud_config: this.configuration_filter,
      };
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        data: data,
        url: process.env.VUE_APP_BASEURL + "scan/cloud-review-scan/",
      };
      this.$http(options)
        .then((res) => {
          this.$refs.cloudScan.load();
          if (res.data.errors) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.errors,
                autoHideDelay: 5000,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Scan Successfully created",
                autoHideDelay: 5000,
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.configuration_filter = null;
            this.$nextTick(() => this.$refs.observer.reset());
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isloading = false; // Stop loading for this item
        });
    },
    gotoCreateCloud() {
      this.$router.push({ path: "/cloud-configuration/add" });
    },
  },
};
</script>
<style scoped>
::v-deep .dropdown-menu {
  max-height: 100px;
  overflow-y: auto;
}
.bg-dark-blue {
  /* background-color: #171153 !important; */
  background-color: #1a457b1f !important;
  /* background-color: #1e176c !important; */
}

[dir] .dark-layout .bg-dark-blue {
  background-color: #131721 !important;
  color: #fff;
}
</style>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.bg-background {
  background: #fff !important;
}
[dir] .dark-layout .bg-background {
  // background: #283046 !important;
  background: #253e42 !important;
  border-color: #404656;
}
</style>
